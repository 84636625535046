'use client'
import React, { useState, useEffect, useRef } from "react";
import Image from 'next/image'
import Link from 'next/link'
import { IoIosArrowBack } from "react-icons/io";

import NavLink from './Shared/NavLink'

import getSectionLinks from '../utils/getLinks';

import styles from '../styles/components/HamburgerMenu.module.css'

const defaultStyling = {
  hamburger_container: {
    div1: {
      transform: 'rotate(0deg)',
    },
    div2: {
      transform: 'translateX(0)',
      opacity: 1,
    },
    div3: {
      transform: 'rotate(0deg)',
    }
  },
  mobile_menu: {
    width: '0px'
  },
  mobile_menu_link: {
    opacity: 0
  },
  wrapper: {
    opacity: 0,
    visibility: 'hidden' as ('hidden' | 'visible'),
    transition: 'opacity .32s cubic-bezier(.4,0,.6,1) 80ms,visibility .32s step-end 80ms'
  }
}

function HamburgerMenu({ linksArr }: { linksArr: NavLink[] }) {


  const [open, setOpen] = useState(false)

  const [animatedHamburgerStyling, setAnimatedHamburgerStyling] = useState(defaultStyling.hamburger_container)
  const [animatedMenuStyling, setAnimatedMenuStyling] = useState(defaultStyling.mobile_menu)
  const [animatedLinkStyling, setAnimatedLinkStyling] = useState(defaultStyling.mobile_menu_link)
  const [animatedWrapperStyling, setAnimatedWrapperStyling] = useState(defaultStyling.wrapper)

  const setStyling = (opening: boolean) => {
    const timing = [0, 400, 800]

    if (opening) {
      document.body.style.overflow = 'hidden'
    }
    else {
      document.body.style.overflow = 'unset'
    }

    setTimeout(() => {
      setAnimatedHamburgerStyling(
        opening ? {
          div1: { transform: 'rotate(45deg)' },
          div2: { transform: 'translateX(20px)', opacity: 0 },
          div3: { transform: 'rotate(-45deg)' }
        } : defaultStyling.hamburger_container
      )
    }, timing[0])

    setTimeout(() => {
      setAnimatedMenuStyling(
        opening ? { width: '100vw' } : defaultStyling.mobile_menu
      )
    }, timing[opening ? 1 : 2])

    setTimeout(() => {
      setAnimatedWrapperStyling(
        opening ? {
          opacity: 1,
          visibility: 'visible',
          transition: 'opacity .32s cubic-bezier(.4,0,.6,1) 80ms,visibility .32s step-start 80ms'
        } : defaultStyling.wrapper
      )
    }, timing[opening ? 1 : 2])

    setTimeout(() => {
      setAnimatedLinkStyling(
        opening ? { opacity: 1 } : defaultStyling.mobile_menu_link
      )
    }, timing[opening ? 2 : 1])

    setOpen(!open)
  }

  return (
    <>
      <div style={animatedWrapperStyling} className={styles.mobile_menu_background} onClick={() => setStyling(!open)}></div>
      <div className={styles.hamburger_container} onClick={() => setStyling(!open)}>
        <div style={animatedHamburgerStyling.div1} />
        <div style={animatedHamburgerStyling.div2} />
        <div style={animatedHamburgerStyling.div3} />
      </div>
      <div style={animatedMenuStyling} className={styles.mobile_menu}>
        {getSectionLinks('header', linksArr).map((link) => link?.children && link?.children?.length > 0 ? (
            <div key={`mobile-${link.label}`} onClick={() => { setStyling(false) }} style={animatedLinkStyling} className={styles.mobile_menu_link}>
              <div className={styles.mobile_menu_links_dropdown_div}>
                {link.label}
                <IoIosArrowBack />
              </div>
              <div className={styles.mobile_menu_link_underline}>
              </div>
              {link?.children && link?.children?.length > 0 &&
                <div className={styles.mobile_menu_dropdown_wrapper}>
                  {link.children.map((child) =>
                    <Link href={child.href} key={child.href}>{child.label}</Link>
                  )}
                </div>
              }
            </div>
        ) : (
          <Link onClick={() => { setStyling(false) }} key={`link-${link.href}`} href={link.href} style={animatedLinkStyling} className={styles.mobile_menu_link}>
            {link.label}
            <div className={styles.mobile_menu_link_underline}></div>
          </Link>
          // <Link onClick={() => { setStyling(false)}} key={link.href} href={link.href} style={animatedLinkStyling} className={styles.mobile_menu_link}>
          //   {link.label}
          // </Link>
          // <NavLink key={link.href} link={link} />
        ))}
      </div>
    </>
  )
}

export default HamburgerMenu
