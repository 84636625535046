'use client';

import dynamic from 'next/dynamic';

import { Suspense, useEffect, useState } from 'react';
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";

const CustomCursor = dynamic(() => import('./CustomCursor'), { ssr: false });

import styles from '../styles/components/ToggleColorMode.module.css'
// import CustomCursor from './CustomCursor';

type Theme = 'light' | 'dark';

function ThemeToggle({ initialValue }: { initialValue: Theme }) {
  const [theme, setTheme] = useState<Theme>(initialValue);

  useEffect(() => {
    if (theme) {
      document.cookie = `theme=${theme};path=/;`;
      document?.querySelector('html')?.setAttribute('data-theme', theme);
    } else {
      setTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    }
  }, [theme]);

  return (
    <>
      <Suspense fallback={<></>}>
        <CustomCursor />
      </Suspense>

      <div className={styles.toggle_container} onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
        <div style={theme === 'dark' ? { right: '27px' } : { right: '2px' }} className={styles.toggle_icon}>{theme === 'dark' ? <BsFillMoonFill /> : <BsFillSunFill />}</div>
      </div>
    </>
  );
}

export default ThemeToggle;