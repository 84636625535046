'use client'

import { useEffect } from "react";
import Image from 'next/image'

import Logo from '../../public/images/logo';



const HeaderLogo = () => {
  useEffect(() => {

    const observer = () => {
      const observer = new IntersectionObserver(
        ([e]) => {
          return e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1), { threshold: [1] }
        }
      );

      const nav = document.querySelector("#navbar");;
      if (nav) observer.observe(nav);

      return () => {
        observer.disconnect();
      };
    }
    window.addEventListener('scroll', observer);

    return () => {
      window.removeEventListener('scroll', observer);
    }

  }, [])

  return (
    // <Image
    //   id="header-logo"
    //   src={"/images/logo.svg"}
    //   alt="Logo"
    //   width={249}
    //   height={80}
    //   priority={true}
    //   style={{
    //     objectFit: 'contain',
    //   }}
    // />
    <Logo
      id="header-logo"
      // src={"/images/logo.svg"}
      // alt="Logo"
      width={249}
      height={80}
      // priority={true}
      style={{
        objectFit: 'contain',
      }}
    />
  )

}

export default HeaderLogo;