const getSectionLinks = (text: NavLinkSections, links: NavLink[]) => {
  const arr = links.filter(link => link.section.includes('all') || link.section.includes(text)).map((link,i) => {
    return ({
      ...link,
      sectionOrder: { [text]:(link.sectionOrder === undefined || link.sectionOrder[text] === undefined || !link.sectionOrder[text]) ? i : link.sectionOrder[text]}
    })
  }).sort((a,b) => {
    const aOrder = a.sectionOrder[text] || 0
    const bOrder = b.sectionOrder[text] || 0
    return aOrder - bOrder
  })

  return arr

}

export default getSectionLinks