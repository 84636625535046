'use client'

import React, { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

const availableRoutes = [
  '/blogs/',
]

const ProgressiveScroll = () => {
  const pathname = usePathname()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('scroll', () => {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        if (ref.current) {
          ref.current.style.width = `${(scrollTop / height) * 100}%`;
        }
      });
    };

  }, [ref, pathname])

  // useEffect(() => {
  //   const cursorSmall = document.querySelector('.small');

  //   const positionElement = (e: { clientY: any; clientX: any } ) => {
  //     const mouseY = e.clientY;
  //     const mouseX = e.clientX;

  //     if (cursorSmall && cursorSmall instanceof HTMLElement) {
  //       cursorSmall.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
  //     }

  //   }

  //   window.addEventListener('mousemove', positionElement)

  // }, [])


  return pathname && pathname.length > 0 && pathname?.includes('/blog/') && (
    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', backgroundColor: 'var(--background-color)', height: 7, position: 'absolute', bottom: 0, left: 0 }}>
      <div style={{ height: '100%', backgroundColor: 'var(--secondary-color)' }} ref={ref}></div>
    </div>
  )
}

export default ProgressiveScroll