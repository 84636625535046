'use client'

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import ImageCard from "./Shared/ImageCard";
import formatBlog from "../utils/formatData/formatBlog";

const DropdownContainer = ({ data, styles }: { data: NavlinkChild[], styles: { readonly [key: string]: string } }) => {

  const [image, setImage] = useState(data[0].image)
  const [selectedIdx, setSelectedIdx] = useState(0)

  return (
    <div className={styles.header_dropdown_container_wrapper}>
      <div className={styles.header_dropdown_container}>
        <div className={styles.header_dropdown_link_container}>
          {data.map((childLink, idx) =>
            <Link onMouseEnter={() => setSelectedIdx(idx)} className={styles.header_dropdown_link} key={childLink.label.replaceAll(' ', '-')} href={childLink.href}>
              {/* // <Link onMouseEnter={() => setImage(data[idx].image)} className={styles.header_dropdown_link} key={childLink.label.replaceAll(' ', '-')} href={childLink.href}> */}
              {childLink.label}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default DropdownContainer;